import { defineStore } from "pinia";
import moment from "moment";
import { jwtDecode, type JwtPayload } from "jwt-decode";
import type { GarageVehicle, ParkingHistorySession } from "@/types";
import { getParkingLocation } from "@/services/protectedApi";

export interface AppUserStoreState {
  user: IAMServiceUser | null;
  authToken: string | null;
  vehicles: GarageVehicle[];
  paymentMethods: any[];
  parkingHistorySessions: ParkingHistorySession[];
  locations: Map<string, PMC.Parking.Location>;
  navigationPreference: string | null;
  dismissedBannerMessages: { locationId: string; date: string }[] | null;
}

const LS_APP_USER_STORE_KEY = "PMCWebParking:appUserStore";
const LS_NAVIGATION_PREFERENCE_KEY = `${LS_APP_USER_STORE_KEY}::navigationPreference`;
const LS_BANNER_MESSAGE_KEY = "PMCWebParking:dismissedBannerMessages";

export const useAppUserStore = defineStore({
  id: "appUserStore",
  state: (): AppUserStoreState => ({
    user: null,
    authToken: null,
    vehicles: [],
    paymentMethods: [],
    parkingHistorySessions: [],
    locations: new Map(),
    navigationPreference: null,
    dismissedBannerMessages: null,
  }),
  getters: {
    isAuthenticated(): boolean {
      if (!this.authToken || !this.user) {
        return false;
      }

      // Check to ensure the token is not expired.
      const decoded = jwtDecode<JwtPayload>(this.authToken);
      const expirationMoment = moment.unix(decoded.exp as number);

      return expirationMoment.isAfter(moment());
    },
  },
  actions: {
    setUserData(user: IAMServiceUser, authToken: string) {
      this.user = user;
      this.authToken = authToken;
    },
    getUserData() {
      return { user: this.user, authToken: this.authToken };
    },
    resetUserData() {
      this.user = null;
      this.authToken = null;
    },
    signOut() {
      this.resetUserData();
    },
    async getLocation(locationId: string) {
      let location: PMC.Parking.Location | undefined =
        this.locations.get(locationId);

      if (!location) {
        try {
          location = (await getParkingLocation(
            locationId,
          )) as unknown as PMC.Parking.Location;
        } catch (error) {
          console.error(error);
        }
      }

      if (!location) {
        return null;
      }

      this.locations.set(locationId, location);

      return location;
    },
    setNavigationPreference(preference: string) {
      localStorage.setItem(LS_NAVIGATION_PREFERENCE_KEY, preference);

      this.navigationPreference = preference;
    },
    getNavigationPreference() {
      if (!this.navigationPreference) {
        this.navigationPreference =
          localStorage.getItem(LS_NAVIGATION_PREFERENCE_KEY) || "google";
      }

      return this.navigationPreference;
    },
    dismissBannerMessage(locationId: string) {
      if (!locationId) {
        return;
      }

      const dismissedBannerMessages = this.getDismissedBannerMessages();

      dismissedBannerMessages!.push({
        locationId,
        date: moment().format("YYYY-MM-DD"),
      });

      localStorage.setItem(
        LS_BANNER_MESSAGE_KEY,
        JSON.stringify(dismissedBannerMessages),
      );

      this.dismissedBannerMessages = dismissedBannerMessages;
    },
    getDismissedBannerMessages() {
      if (this.dismissedBannerMessages === null) {
        const storedDismissedBannerMessages = localStorage.getItem(
          LS_BANNER_MESSAGE_KEY,
        );

        if (!storedDismissedBannerMessages) {
          this.dismissedBannerMessages = [];

          return this.dismissedBannerMessages;
        }

        let dismissedBannerMessages: { locationId: string; date: string }[];

        try {
          dismissedBannerMessages = JSON.parse(storedDismissedBannerMessages);
        } catch {
          dismissedBannerMessages = [];
        }

        // Filter out any location banner messages that were dismissed more than 30 days ago.
        const filteredList = dismissedBannerMessages.filter(
          (r: { date: string }) =>
            moment(r.date).isAfter(moment().subtract(30, "days")),
        );

        localStorage.setItem(
          LS_BANNER_MESSAGE_KEY,
          JSON.stringify(filteredList),
        );

        this.dismissedBannerMessages = filteredList;
      }

      return this.dismissedBannerMessages;
    },
  },
});
