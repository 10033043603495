// redirect amerigo lots to a parent location
// TODO: remove this if/when signage is updated
export const redirects = [
  {
    path: "/start/2c6677e0-6456-47c5-9877-d464c0f1e555",
    redirect: "/start/5ba03437-c656-4379-84d4-e614e4619074",
  },
  {
    path: "/start/b969440d-8bb2-44e8-8512-81eaa351a7d0",
    redirect: "/start/5ba03437-c656-4379-84d4-e614e4619074",
  },
  {
    path: "/start/190eb461-a256-4388-8265-87c27f831dac",
    redirect: "/start/5ba03437-c656-4379-84d4-e614e4619074",
  },
  {
    path: "/start/5d367d25-f8d5-4802-81f8-9f2f83e6b417",
    redirect: "/start/5ba03437-c656-4379-84d4-e614e4619074",
  },
  // 620 E River St Savannah redirect to entry gate.
  {
    path: "/start/9d896d3b-a29c-46ee-9f77-27001ddea575",
    redirect: "/f/6d982d02-f1cd-4136-abf9-2afdb6846015",
  },
];
